import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {snakeCase, toUpper} from 'lodash';
import fileDownload from 'js-file-download';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {
    commonConstants,
    settingConstants,
    DEVICE_PAGE_SIZE_OPTIONS,
    LEDBOX,
    RLEDBOX,
    RSIGNAGE,
    SIGNAGE,
    SPLAYER,
    WPLAYER
} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService, settingService} from '../../services';
import {menuAction, popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import DeviceNameCell from '../../components/device/DeviceNameCell';
import Pagination from '../../components/table/Pagination';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import './Device.css';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import {useMISOpt} from '../../components/misopt';
import {useTrGroupProps} from '../../helper/tables';
import {getMixString} from '../../language/languageUtils';
import {updateCache} from "../../helper/cache/tableCache";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const GROUP_TYPE = 'unapproval';
const exclusiveGroupDeviceType = ['FLIP','APLAYER','RIPLAYER','RSPLAYER'];
const heightOffset = 201;

const UnapprovedDevice = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt, misOptionDevice} = useMISOpt();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [licenseUsage, setLicenseUsage] = useState([]);
    const [hasMigrationLicense, setHasMigrationLicense] = useState(false);
    
    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);
    const [isE2E, setIsE2E] = useState(false);
    const [e2eLicenseSystem, setE2ELicenseSystem] = useState(settingConstants.E2E_LICENSE_SYSTEM_PBP);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        deviceService.fetchDeviceFilter({
            startIndex: page * pageSize + 1,
            pageSize,
            groupType: GROUP_TYPE,
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            setData({
                ...data, 
                loading: false, 
                items: res.items.map(item => {
                    item.deviceName = item.deviceName.replace(/"/g, '_');
                    return item;
                }), 
                totalCount: res.totalCount
            });
            updateCache('DEVICE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent);
            dispatch(menuAction.updateSubMenuCounter('DEVICE'));
        });
        fetchLicenseUsage();
    };

    const fetchLicenseUsage = () => {
        deviceService.fetchLicenseUsage()
         .then( res => {
             setLicenseUsage(res.items);
             res.items && res.items.forEach(item => {
                 if(item.licenseType === "Migration License" && item.usedLicenseCount > 0){
                    setHasMigrationLicense(true);
                 }
             })
         })
    }

    const fetchE2EMode = ()=>{
        settingService.fetchServerSettings()
            .then(res => {
                setIsE2E(res.items.general.isE2E);
                setE2ELicenseSystem(res.items.general.e2eLicenseSystem);
            });
    }

    const displayLicenseUsage = () => {
        return(
            <div className="device_list_warring">
                {
                    licenseUsage.map((el, i) =>
                        <div key={i} className={"float_l"}  style={{marginRight:15}}>
                            <span style={{fontWeight:'bold'}}>{el.licenseType}</span> :
                            <span style={{fontWeight:'bold'}}>{el.usedLicenseCount}</span>/
                            <span style={{fontWeight:'bold'}}>{el.totalLicenseCount}</span>
                        </div>
                    )
                }
            </div>
        );
    };

    const handleDelete = () => {
        const deviceIds = selected.current.map(s => items[s].deviceId);
        dispatch(popupAction.addPopup({id: commonConstants.LOADING_POPUP, type: commonConstants.LOADING_POPUP}));
        deviceService.deleteDevices(deviceIds).then(res => {
            toastr.success(t('ALERT_SUCCESS_DELETE'));
            setFilter({...filter, page: 0});
            // fetchData(); 시작페이지가 1로 돌아가야함
            dispatch(menuAction.updateSubMenuCounter('DEVICE'));
        }).catch(error => {
            toastr.error(error);
        }).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
        });
    };

    const requestApproval = approvalInfo => {
        if(isE2E && e2eLicenseSystem=='PBP'&&   (approvalInfo.accountCode==undefined || approvalInfo.accountCode=='') ) {
            toastr.error('Select the company');
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
            return;
        }else if(isE2E && e2eLicenseSystem=='SLM' && (approvalInfo.soldToCodeId==undefined || approvalInfo.soldToCodeId=='')) {
            toastr.error('Select the company');
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
            return;
        }
        deviceService.approveDevices(approvalInfo).then(res => {
            toastr.success(`${t('TEXT_APPROVAL_P')} - ${t('TEXT_SUCCESS_P')}`);
            dispatch(popupAction.closePopup(commonConstants.APPROVE_DEVICE));
            setFilter({...filter, page: 0});
            // fetchData(); 시작페이지가 1로 돌아가야함
            dispatch(menuAction.updateSubMenuCounter('DEVICE'));
            dispatch(menuAction.loadGroup('DEVICE', 'DEVICE_BY_GROUP', true));
            misopt.refreshDeviceTypes();
        }).catch(error => {
            if(error.status === 'vwllayout_group_APPROVAL_FAIL') {
                toastr.error(t('MIS_MESSAGE_DEVICE_DEVICE_APPROVAL_FAIL_VWL_P'));
            } else if(error.status === 'device_approval_max_connection_over') {
                toastr.error(t('MESSAGE_DEVICE_DEVICE_APPROVAL_MAX_CONNECTION_OVER_P'));
            } else {
                let reason = `${t('TEXT_APPROVAL_P')} - ${t('TEXT_FAIL_P')}`;
                error.items.unapprovedList.forEach((reject) => {
                    if (reject.reasonCode === "400607") {
                        reason = t("MIS_SID_CAFEB_LICENSE_REQUIRED_DOT");
                    } else if (reject.reasonCode === "400608") {
                        reason = t("MIS_MESSAGE_DEVICE_DEVICE_APPROVAL_FAIL_VWL_P");
                    } else if (reject.reasonCode === "400611") {
                        reason = t("MESSAGE_DEVICE_DEVICE_APPROVAL_MAX_CONNECTION_OVER_P");
                    }
                });
                toastr.error(reason);
            }
            // } else if(json.status == 'vwllayout_group_APPROVAL_FAIL'){
            //     toastr.error($.i18n.prop('MIS_MESSAGE_DEVICE_DEVICE_APPROVAL_FAIL_VWL_P'));
            // } else if (json.status == 'device_approval_max_connection_over') {
            //     var data = json.data;
            //     toastr.error($.i18n.prop('MESSAGE_DEVICE_DEVICE_APPROVAL_MAX_CONNECTION_OVER_P'));
            // }
        }).finally(() => dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));
    };
    
    const handleApprove = approval => {
        const deviceIds = selected.current.map(s => items[s].deviceId);
        const approvalInfo = {...approval, deviceIds,isE2E, e2eLicenseSystem};

        dispatch(popupAction.addPopup({id: commonConstants.LOADING_POPUP, type: commonConstants.LOADING_POPUP}));
        deviceService.checkDeviceApproval(approval.groupId, deviceIds).then(res => {
            const {items: {unapproveList = []} = {}} = res;
            if(unapproveList && unapproveList.length > 0) {
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                const [{deviceId, reasonCode, reason}] = unapproveList;

                if(reasonCode === '400624') {
                    toastr.error(t('MIS_MESSAGE_REDUNDANCY_FAIL_APPROVAL_P'));
                } else if(reasonCode === '400623') {
                    toastr.error(t('MIS_SID_NOT_ADD_DEVICES_GRUP_SET_FIRST'));
                } else if (reasonCode === '400625') {
                    // toastr.error(getErrorMessageWithCode(parseInt(reasonCode), reason));
                    dispatch(popupAction.addPopup({
                        id: commonConstants.COMMON_CONFIRM_POPUP,
                        type: commonConstants.COMMON_CONFIRM_POPUP,
                        title: t('COM_BUTTON_CONFIRM'),
                        message: t('MIS_MESSAGE_DELETE_SCHEDULE_CHANGE_DEV_GROUP_P'),
                        useMessageLine: false,
                        onClickYes: () => {
                            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                            dispatch(popupAction.addPopup({id: commonConstants.LOADING_POPUP, type: commonConstants.LOADING_POPUP}));
                            requestApproval(approvalInfo);
                        },
                        onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
                    }));
                } else if(reasonCode === '400621') {
                    dispatch(popupAction.addPopup({
                        id: commonConstants.VWL_LAYOUT_DEVICE_REPLACE,
                        type: commonConstants.VWL_LAYOUT_DEVICE_REPLACE,
                        selDeviceId: deviceId,
                        modelName: selectedCnt > 1 ? '' : (items.find(item => item.deviceId === deviceId) || {}).deviceModelName,
                        approvalInfo,
                        onOk: () => {
                            dispatch(popupAction.closePopup(commonConstants.VWL_LAYOUT_DEVICE_REPLACE));
                            setFilter({...filter, page: 0});
                        },
                        onClose: () => dispatch(popupAction.closePopup(commonConstants.VWL_LAYOUT_DEVICE_REPLACE))
                    }));
                } else {
                    toastr.error(t('MESSAGE_DEVICE_DEVICE_APPROVAL_FAIL_P'));
                }
            } else {
                requestApproval(approvalInfo);
            }
        }).catch(error => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            toastr.error(t('MESSAGE_DEVICE_DEVICE_APPROVAL_FAIL_P'));
        });
    };

    const checkDeviceType = () => {
        return selected.current.every(s => items[selected.current[0]].deviceType === items[s].deviceType) 
        // !selected.current.some(s => !(items[selected.current[0]].deviceType === items[s].deviceType));
    };

    const onClickApprove = () => {
        // if(!checkDeviceType()) {
        //     toastr.warning(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
        //     return;
        // };
        if(hasMigrationLicense){
            toastr.error(getMixString(['MIS_SID_MIX_CAAPR_IT_NOT_APPROVE_NEW_WHILE_ACTIVATE', 'MIS_SID_CAAPR_MIGRATION_LICENSE']));
            return;
        }

        const devices = selected.current.map(idx => items[idx]);
        let hasSignage = false, hasUhdLedBox = false, hasFhdLedBox = false, supportCabinetSetting = false, deviceType = '';

        if(devices.length === 1) {
            if(devices[0].deviceType === SIGNAGE || devices[0].deviceType === RSIGNAGE) {
                // hasSignage++;
                hasSignage = true;
            }

            if(devices[0].deviceType === LEDBOX || devices[0].deviceType === RLEDBOX) {
                if(devices[0].supportUhd) {
                    // hasUHDLedBox++;
                    hasUhdLedBox = true;
                } else {
                    // hasFHDLedBox++;
                    hasFhdLedBox = true;
                }

                if(devices[0].supportCabinetSetting) {
                    // support_cabinetSetting = true;
                    supportCabinetSetting = true;
                }
            }
        }

        if(devices.length > 1 && hasSignage){
            toastr.warning(t('MIS_SID_NOT_APPROVE_SIGNAGE_PLAYER_SELECT_APPROVE'));
            return;
        } else if(devices.length > 1 && (hasUhdLedBox || hasFhdLedBox)){
            toastr.warning(t('MIS_SID_SERVER_NOT_APPROVE_LED_S_BOX_MSG'));
            return;
        } else if(devices.length === 1 && (hasFhdLedBox || hasUhdLedBox)) {
            deviceType = LEDBOX;
        } else if(devices.length === 1 && hasSignage) {
            deviceType = SIGNAGE;
        }

        if(devices.length >1 && isE2E){
            toastr.error('You can approve only one device at one time');
            return;
        }

        let cntExclusiveGroupDeviceType = 0, indexMinusDeviceType;
        let isSameExclusiveDeviceType = true;

        devices.every(device => {
            const i = exclusiveGroupDeviceType.findIndex(type => type === device.deviceType);
            if(i >= 0) {
                if(cntExclusiveGroupDeviceType === 0){
                    indexMinusDeviceType = i;
                } else { 
                    if (indexMinusDeviceType !== i){ 
                        isSameExclusiveDeviceType = false; 
                        return false;
                    }
                }
                cntExclusiveGroupDeviceType++;
            }
            return true;
        });

        if((cntExclusiveGroupDeviceType > 0 && cntExclusiveGroupDeviceType !== devices.length) || isSameExclusiveDeviceType === false){
            toastr.warning(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
            return;
        }

        dispatch(popupAction.addPopup({
            id: commonConstants.APPROVE_DEVICE,
            type: commonConstants.APPROVE_DEVICE, 
            devices,
            deviceType,
            hasSignage,
            hasUhdLedBox,
            hasFhdLedBox,
            supportCabinetSetting,
            isE2E,
            e2eLicenseSystem,
            onSave: handleApprove,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.APPROVE_DEVICE)),
        }));
    };

    const onClickDelete = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP, 
            title: t('TEXT_TITLE_DELETE_DEVICE_P'), 
            message: t('MESSAGE_DEVICE_DELETE_DEVICE_P'),
            onClickYes: () => {
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                handleDelete(); 
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    };

    const onConversion = () => {
        if(!checkDeviceType()) {
            toastr.error(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
            return;
        };

        const deviceType = items[selected.current[0]].deviceType;
        if(deviceType !== WPLAYER && deviceType !== SPLAYER) {
            toastr.error(t('MIS_SID_CAFEB_PLZ_SELECT_CONVERSION'));
            return;
        }

        if(!selected.current.every(s => {
            const option = misOptionDevice(toUpper(items[s].deviceType), parseInt(items[s].deviceTypeVersion));
            return option && option.deviceSwitchSocLite;
        })) {
            toastr.error(t('MIS_SID_CAFEB_PLZ_SELECT_CONVERSION'));
            return;
        }
        
        const deviceIds = selected.current.map(s => items[s].deviceId);
        Promise.all(deviceIds.map(deviceId => deviceService.fetchDeviceById(deviceId))).then(res => {
            const supportWplayer = res.every(r => r.items.supportWplayer);

            if(!supportWplayer) {
                toastr.error(t('MIS_SID_CAFEB_PLZ_SELECT_CONVERSION'));
            } else {
                dispatch(popupAction.addPopup({
                    id: commonConstants.DEVICE_TYPE_CONVERSION,
                    type: commonConstants.DEVICE_TYPE_CONVERSION,
                    conversionType: 'unapproved',
                    deviceIds,
                    deviceType,
                    onOk: () => {
                        fetchData();
                        dispatch(popupAction.closePopup(commonConstants.DEVICE_TYPE_CONVERSION));
                    },
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.DEVICE_TYPE_CONVERSION)),
                }));
            }
        }).catch(error => console.log(error));
    };

    const onExport = exportType => {
        const {keyword, sorted: [{id, desc}]} = filter;
        exportType = 'EXCEL';
        deviceService.exportUnapprovedDevices({
            exportType,
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    const onExportGroupCode = () => {
        deviceService.exportUnapprovedDevicesGroupCode().then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    const updateDimensions = () => {
        let height = window.innerHeight - heightOffset;
        setStyle({
            ...style,
            height: height
        })
    };

    useEffect(() => {
        updateDimensions();
        fetchE2EMode();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        } else {
            fetchLicenseUsage();
        }
    }, [filter]);

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id='AllDevice_all'
                        classname="table"
                        name="check"
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname="table"
                        name="check"
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
            width: 300,
            Cell: ({original}) => <DeviceNameCell device={original} disableLink showPowerIcon={false} showConvertable={original.supportWplayer} />,
        },
        {
            Header: t('DID_ADMIN_LICENSE_MAC'),
            accessor: 'deviceId',
        },
        {
            Header: t('TABLE_IP_ADDR_P'),
            accessor: 'ipAddress',
            Cell: data => <span style={{whiteSpace: 'normal',wordBreak:'break-all'}}>{data.value}</span>
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: 'deviceModelName',
        },
        {
            Header: t('TABLE_DEVICE_SERIAL_P'),
            accessor: 'serialDecimal',
        },
        {
            Header: t('ADMIN_DEVICEEVENT_ALARMRULE_ASSIGNRULE_CONTENTS_REGISTERED'),
            accessor: 'createDate',
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        }
    ], [items]);

    const searchPlaceHolder = `${t('TABLE_DEVICE_NAME_P')}, ${t('DID_ADMIN_LICENSE_MAC')}, ${t('TABLE_IP_ADDR_P')}, ${
        t('ADMIN_DEVICEEVENT_ALARMRULE_ASSIGNRULE_CONTENTS_MODEL')}, ${t('COM_TEXT_FIRMWARE_P')}`;

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%', display: props.currContent === 'UNAPPROVED_DEVICE' ? 'block':'none'}}>
            <div className="contents_buttonWrap" style={{height:32, marginBottom:0}}>
                <div className="leftButton">
                    <WhiteButton id={"DEVICE_APPROVE"} name={t('COM_BUTTON_APPROVAL')} disable={selectedCnt <= 0} onClick={onClickApprove} />
                    <WhiteButton id={"DEVICE_DELETE"} name={t('COM_BUTTON_DELETE')} disable={selectedCnt <= 0} onClick={onClickDelete} />
                    <WhiteButton id={"MIS_TEXT_DEVICE_CONVERSION_P"} name={t('MIS_TEXT_DEVICE_CONVERSION_P')} disable={selectedCnt <= 0} onClick={onConversion} />
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                    <WhiteButton id={"DEVICE_EXPORT_GROUP_CODE"} name={t('MIS_SID_20_EXPORT_GROUP_CODE')} onClick={onExportGroupCode} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            {
                displayLicenseUsage()
            }
            <div className='device_list_view_wrap' style={{width: '100%'}}>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    className="-striped -highlight"
                    style={style}
                    showPagination={false}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide}/>
            </div>
        </div>
    );
};

export default UnapprovedDevice;