import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {snakeCase, toUpper} from 'lodash';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService, userService} from '../../services';
import {popupAction} from "../../actions";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import './Device.css';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import CircleButton from '../../components/button/CircleButton';
import RefreshIcon from '../../images/icon/refresh_normal.png';
import {useTrGroupProps} from '../../helper/tables';
import {updateCache} from "../../helper/cache/tableCache";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useMISOpt} from '../../components/misopt';
import {getErrorMessage} from 'helper/responseHandler';

const PublishStatusCell = ({completedCount, totalCount, onClick}) => {
    const {t} = useTranslation();
    const width = completedCount * 100 / totalCount;

    return (
        <>
        {     
            (completedCount === totalCount) ?
            <span onClick={onClick} className='link_cell'>{t('COM_MIA_SID_STATUS_PUBLISHED')}</span>
            :
            <span onClick={onClick} style={{
                height: 4,
                width: '100%',
                background: '#edf2f7',
                padding: 0,
                position: 'relative',
                cursor: 'pointer',
            }}>
                <span style={{
                    width: `${width}%`,
                    height: 4,
                    position: 'absolute',
                    background: '#66ca84'
                }}></span>
                <span className='link_cell'>{`${completedCount}/${totalCount} ${t('TEXT_DEVICE_P')}`}</span>
            </span>            
        }
        </>
    );
};

const heightOffset = 204;
const defaultSort = {id: 'createDate', desc: true};

const Preset = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, ] = useFilter({
        ...props.cache.filter
    });

    const [authority] = useState(getAuthority('DEVICE'));
    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
        deviceSecurity: props.cache.deviceSecurity ? props.cache.deviceSecurity : false
    });
    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        Promise.all([
            deviceService.fetchDevicePresetFilter({
                startIndex: page * pageSize + 1,
                pageSize,
                sortColumn: toUpper(snakeCase(id)),
                sortOrder: desc ? 'desc' : 'asc',
            }),
            userService.fetchUserLoginInformation()
        ]).then(res => {
            setData({...data, loading: false, items: res[0].items, totalCount: res[0].totalCount,
                deviceSecurity: res[1].items.hasDeviceSecurity
            });
            updateCache('DEVICE', {items: res[0].items, filter: filter, totalCount: res[0].totalCount, deviceSecurity : res[1].items.hasDeviceSecurity}, props.currContent);
        });
    };

    const handleDelete = () => {
        if(selected.current.length < 1) {
            return;
        }

        // {presetIds: selected.current.map(idx => items[idx].preconfigId)}
        deviceService.deleteDevicePreset(selected.current.map(idx => items[idx].preconfigId)).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            setFilter({
                ...filter,
                page: 0,
            }, fetchData());
        }).catch(error => {
            console.log(error);
            toastr.error(getErrorMessage(error));
        }).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
        });
    };

    const onDelete = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP, 
            width: 360,
            height: 180,
            title: t('COM_BUTTON_CONFIRM'), 
            message: t('ALERT_WANT_DELETE'),
            onClickYes: handleDelete, 
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    };

    const onAdd = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.ADD_PRESET,
            type: commonConstants.ADD_PRESET,
            deviceSecurity: data.deviceSecurity,
            onSave: () => fetchData(),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_PRESET)),
        }));
    };

    const onClickPreset = (preset) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.EDIT_PRESET_DETAIL,
            type: commonConstants.EDIT_PRESET_DETAIL,
            mode: 'edit',
            preset,
            deviceSecurity: data.deviceSecurity,
            onSave: () => {
                fetchData();
                dispatch(popupAction.closePopup(commonConstants.EDIT_PRESET_DETAIL));
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_PRESET_DETAIL)),
        }));
    };

    const onEdit = () => {
        if(selected.current.length === 1) {
            onClickPreset(items[selected.current[0]]);
        }
    };

    const onCopy = () => {
        if(selected.current.length === 1) {
            dispatch(popupAction.addPopup({
                id: commonConstants.ADD_PRESET,
                type: commonConstants.ADD_PRESET,
                mode: 'copy',
                sourcePreset: items[selected.current[0]],
                onSave: () => {
                    fetchData();
                    dispatch(popupAction.closePopup(commonConstants.ADD_PRESET));
                },
                onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_PRESET)),
            }));
        }
    };

    const handlePublish = groups => {
        if(!groups || groups.length === 0) {
            return;
        }

        deviceService.updateDevicePreset({
            preconfigId: items[selected.current[0]].preconfigId, 
            deviceGroupIds: groups.map(group => group.groupId),
        }).then(res => {
            dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
            fetchData();
        }).catch(error => {
            toastr.error(getErrorMessage(error));            
        });
    };

    const onPublish = () => {
        if(selected.current.length !== 1) {
            return;
        }

        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            allEnabled: true,
            checkbox: true,
            save: handlePublish, 
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }));
    };

    const onClickStatus = preset => e => {
        dispatch(popupAction.addPopup({
            id: commonConstants.PRESET_PUBLISH_STATUS,
            type: commonConstants.PRESET_PUBLISH_STATUS,
            preset,
            onClickDevice: (deviceId) => {
                dispatch(popupAction.openDetailView({
                    type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'DEVICE', id: deviceId, hasDeviceSecurity: data.deviceSecurity
                }));                
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.PRESET_PUBLISH_STATUS))
        }));
    };

    const updateDimensions = () => {
        let height = window.innerHeight - heightOffset;
        setStyle({
            height: height
        })
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id='AllDevice_all'
                        classname="table"
                        name="check"
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname="table"
                        name="check"
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('LIST_TITLE_NAME'),
            accessor: 'name',
            width: 300,
            Cell: ({value, original}) => <span className='link_cell' onClick={() => onClickPreset(original)}>{value}</span>
        },
        {
            Header: t('COM_LIST_TITLE_VERSION'),
            accessor: 'version',
            width: 160,
        },
        {
            Header: t('COM_TEXT_INFORMATION_P'),
            sortable: false,
            accessor: 'description',
            width: 300,
        },
        {
            Header: t('MIS_SID_PUBLISH_STATUS'),
            accessor: 'completedDeployCount',
            sortable: false,
            width: 260,
            Cell: ({value, original}) => {
                if(value === 0 && original.totalDeployCount === 0) {
                    return '-';
                }
               return <PublishStatusCell completedCount={value} totalCount={original.totalDeployCount} onClick={onClickStatus(original)} />;
            },
        },
        {
            Header: t('LIST_TITLE_DATE'),
            accessor: 'updateTime',
            width: 180,
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        },
        {
            Header: t('TABLE_ORGANIZATION_P'),
            accessor: 'organizationName',
        },
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'PRESET_MANAGEMENT' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                <WhiteButton id={"PRESET_ADD"} name={t('COM_BUTTON_ADD')} onClick={onAdd} authority={authority.CREATE}/>
                    <WhiteButton id={"PRESET_EDIT"} name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} authority={authority.CREATE}/>
                    <WhiteButton id={"PRESET_DELETE"} name={t('COM_BUTTON_DELETE')} disable={selectedCnt < 1} onClick={onDelete} authority={authority.DELETE}/>
                    <WhiteButton id={"PRESET_PUBLISH"} name={t('MIS_SID_PUBLISH')} disable={selectedCnt !== 1} onClick={onPublish} authority={authority.CONTROL}/>
                    <WhiteButton id={"PRESET_COPY"} name={t('COM_IDS_EDIT_COPY')} disable={selectedCnt !== 1} onClick={onCopy} authority={authority.CREATE}/>
                </div>
                <div className='rightButton'>
                    <CircleButton onClick={() => fetchData()}>
                        <span style={{
                            background: `url(${RefreshIcon}) no-repeat 5px 6px`,
                            display: 'inline-block',
                            height: 28,
                            width: 28,
                        }}></span>
                    </CircleButton>
                </div>
            </div>
            <div className='device_list_view_wrap' style={{width: '100%'}}>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    className="-striped -highlight"
                    style={style}
                    showPagination={false}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide} />
            </div>
        </div>
    );
};

export default Preset;