import React from 'react';
import {
    commonService,
    contentService,
    deviceService,
    playlistService,
    rulesetService,
    scheduleService,
    userService,
    settingService
} from "../../services";
import Tree, {TreeNode} from "rc-tree/es";
import MISDialog from "./MISDialog";
import {withTranslation} from "react-i18next";
import {
    jsonToTree,
    jsonToTreeForCategory,
    jsonToTreeForDevice,
    jsonToTreeForDeviceAfterSearch,
    jsonToTreeForPlaylistByMePopup,
    jsonToTreeForUser,
    jsonToTreeForSoldToCode,
    jsonToTreeForBrandCode
} from '../../helper';
import './GroupsPopup.css';
import {SearchButton} from "../button/SearchButton";
import classNames from 'classnames';
import {toastr} from 'helper/toastrIntercept';
import {isEmpty} from 'lodash';
import Checkbox from "../checkbox/Checkbox";
import {DefaultPlayer} from '../icon';
import {getMixString} from '../../language/languageUtils';
import {Loading} from "../loading/Loading";

const Icon = ( mode, group, disabled = false) => (
    <span
        className={classNames(
            'customize-icon',
            {
                'organization': mode !== 'content' && (group.parentGroupId === 0 || group.pGroupId === '#') ,
                'default': (mode !== 'device' && group.parentGroupId > 0) || (mode === 'content' || mode === 'playlist')
            }
        )}
        style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}
    />
)

const findNode = (treeData, groupId) => {
    for(let i = 0; i < treeData.length; i++) {
        const treeNode = treeData[i];
        if(treeNode.groupId == groupId) {
            return treeNode;
        } else {
            if(treeNode.children != undefined){
                const found = findNode(treeNode.children, groupId);
                if(found) {
                    return found;
                }
            }
        }
    }

    return null;
};

const removeInputNode = treeNode => {
    if(treeNode.children && treeNode.children.length > 0) {
        treeNode.children = treeNode.children.filter(node => !node.inputNode);
        treeNode.children.forEach(removeInputNode);
    }
};

class GroupsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            filter: '',
            selected: [],
            checkbox : this.props.checkbox !== undefined ? this.props.checkbox : false,
            checkedKeys: this.props.checkedKeys !== undefined ? this.props.checkedKeys : [],
            expandedKeys: this.props.expandedKeys !== undefined ? this.props.expandedKeys : [],
            checkStrictly: this.props.checkStrictly !== undefined ? this.props.checkStrictly : false,
            selectedKeys: this.props.selectedKeys !== undefined ? this.props.selectedKeys : [],
            autoExpandParent: true,
            inputText:'',
            isCheckAll: false,
            contextMenu: {show: false, position: {x: 0, y: 0}},
            loading: false,
            isSearchMode: false
        }
        this.searchRef = React.createRef();
        this.groupContainerRef = React.createRef();
        this.contextMenuRef = React.createRef();
    }

    componentDidMount() {
        const {mode, t} = this.props;
        this.setState({
            loading: true
        })
        if (mode === 'device') {
            this.getDeviceGroupData();
        } else {
            this.initGroupTree();
        }
    }

    initGroupTree() {
        const {mode, params} = this.props;
        if (mode === 'message_schedule') {
            scheduleService.fetchMessageScheduleGroups().then(
                res => {
                    const groups = jsonToTree(res.items)
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            );
        } else if (mode === 'content_schedule') {
            scheduleService.fetchContentScheduleGroups().then(
                res => {
                    const groups = jsonToTree(res.items)
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            );
        } else if (mode === 'event_schedule') {
            scheduleService.fetchEventScheduleGroups().then(
                res => {
                    const groups = jsonToTree(res.items)
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            );
        } else if (mode === 'content') {
            contentService.fetchGroups(params).then(
                res=> {
                    const groups = jsonToTreeForPlaylistByMePopup(res.items);
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            )
        } else if (mode === 'playlist') {
            playlistService.fetchPlaylistGroups({groupType : "MY_PLAYLIST_GROUP"}).then(
                res=> {
                    const groups = jsonToTreeForPlaylistByMePopup(res.items);
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            )
        } else if (mode === 'category') {
            commonService.fetchCategory().then(
                res=> {
                    res.items.sort((a, b) => a.groupName.toUpperCase() < b.groupName.toUpperCase() ? -1 : a.groupName.toUpperCase() > b.groupName.toUpperCase() ? 1 : 0);
                    this.setState({
                        groups: jsonToTreeForCategory(res.items),
                        loading: false
                    })
                }
            );
        } else if (mode === 'user' || mode === 'ldapUser') {
            userService.fetchOrganizationsGroups(true).then(
                res => {
                    if(mode === 'ldapUser') {
                        let adminIndex = res.items.findIndex(item => item.parentGroupId === -1);
                        if(adminIndex >= 0) {
                            res.items.splice(adminIndex, 1);
                        }
                    }
                    const groups = jsonToTreeForUser(res.items)
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            )
        } else if (mode === 'selectedUser') {
            userService.fetchOrganizations(true).then(
                res=> {
                    let selectedItem = res.items.filter(item => {
                        if ((item.groupDepth === 1 && item.groupId === this.props.organizationId) || (item.groupDepth > 1)) {
                            return true;
                        }
                    });
                    const groups = jsonToTreeForUser(selectedItem)
                    this.setState({
                        groups: groups,
                        loading: false
                    })
                }
            )
        } else if (mode === 'ruleset') {
            rulesetService.fetchRulesetGroups().then(
                res => {
                    const groups = jsonToTree(res.items);
                    this.setState({
                        groups: groups,
                        loading: false
                    });
                }
            )

        } else if (mode === 'brandCode') {
            settingService.fetchBrandCodeList().then(
                res => {
                    const groups = jsonToTreeForBrandCode(res.items)
                    this.setState({
                        groups: groups,
                        loading: false
                    });
                }
            )
        }  else if (mode === 'soldToCode') {
            //SLM Direct
            settingService.fetchSoldToCodeList().then(
                res => {
                    const groups = jsonToTreeForSoldToCode(res.items)
                    this.setState({
                        groups: groups,
                        loading: false
                    });
                }
            )
        } else {
            this.setState({
                loading: false
            })

        }
    }


    initGroupState = () => {
        this.filterKeys = undefined;
        this.setState({
            ...this.state,
            filter: '',
            inputText: '',
            expandedKeys: [],
            selected: [],
            defaultCheckedKeys: [],
            defaultSelectedKeys: [],
            selectedKeys:[],
            isCheckAll: false,
            isSearchMode: false
        });
    }

    getDeviceGroupData = () => {
        const {t, submode, userPermissionItems} = this.props;
        const programId = this.props.programId || undefined;
        const isVwl = this.props.isVwl || undefined;
        const isSync = this.props.isSync || undefined;
        const priority = this.props.priority || undefined;
        const allEnabled = this.props.allEnabled || undefined;
        const organId = this.props.organizationId || undefined;
        const searchKey = this.props.searchKey || undefined;

        if (submode === 'userPermission') {
            if (userPermissionItems !== undefined && userPermissionItems !== null) {
                const groups = jsonToTreeForDevice(userPermissionItems, this.props.checkedKeys, this.props.allowSelectOrganization);
                this.setState({
                    treeData: groups.treeData,
                    defaultCheckedKeys: groups.defaultCheckedKeys,
                    expandedKeys: groups.defaultCheckedKeys !== undefined ? groups.defaultCheckedKeys : [],
                    checkedKeys: this.props.checkedKeys !== undefined ? this.props.checkedKeys : groups.defaultCheckedKeys,
                    selected: groups.selected!== undefined ? groups.selected:this.props.checkedKeys,
                    loading: false
                })
            } else {
                toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            }
        } else {
            deviceService.searchGroupByName({
                mode: 'general',
                searchText: '',
                programId: programId !== undefined ? programId : '',
                searchKey: searchKey,
                videoWallMode: isVwl,
                syncMode: isSync,
                priority: priority,
                allEnabled: allEnabled,
                organizationId: organId
            }).then(
                res => {
                    if (res) {
                        res.items.sort((i1, i2) => i1.groupName.localeCompare(i2.groupName));
                        const groups = jsonToTreeForDevice(res.items, this.props.checkedKeys, this.props.allowSelectOrganization);
                        this.setState({
                            treeData: groups.treeData,
                            defaultCheckedKeys: groups.defaultCheckedKeys,
                            expandedKeys: groups.defaultCheckedKeys !== undefined ? groups.defaultCheckedKeys : [],
                            checkedKeys: this.props.checkedKeys !== undefined ? this.props.checkedKeys : groups.defaultCheckedKeys,
                            selected: groups.selected !== undefined ? groups.selected : this.props.checkedKeys,
                            loading: false
                        })
                    } else {
                        toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                    }
                }
            );
        }
    }

    setLeaf = (treeData, curKey, level) => {
        const loopLeaf = (data, lev) => {
            const l = lev - 1;
            data.forEach((item) => {
                if ((item.key.length > curKey.length) ? item.key.indexOf(curKey) !== 0 :
                    curKey.indexOf(item.key) !== 0) {
                    return;
                }
                if (item.children) {
                    loopLeaf(item.children, l);
                } else if (l < 1) {
                    item.isLeaf = true;
                }
            });
        };
        loopLeaf(treeData, level + 1);
    };

    getNewTreeData = (treeData, curKey, child, level) => {
        const loop = (data) => {
            if (level < 1 || curKey.length - 3 > level * 2) return;
            data.forEach((item) => {
                if (curKey == item.key) {
                    item.children = child;
                } else if (item.children) {
                    loop(item.children);
                }
            });
        };
        loop(treeData);
        this.setLeaf(treeData, curKey, level);
        return treeData;
    };

    generateTreeNodes = (tempGroups) => {
        let nodes = [];
        tempGroups.map(
            group => nodes.push({...group, key: group.groupId, name: group.groupName})
        )
        return nodes;
    };

    //SLM Direct
    generateSoldToCodeTreeNodes = (tempGroups) => {
        let nodes = [];
        tempGroups.map(
            group => nodes.push({...group, key: group.soldToCode, groupId: group.soldToCode, name: group.accountNm, modelCd: group.modelCd, secorgId : group.secorgId})
        )
        return nodes;
    };

    onLoadData = (treeNode) => {
        const groupId = treeNode.props.eventKey;
        const {t, mode, params} = this.props;
        if (mode === 'message_schedule') {
            return new Promise((resolve, reject) => {
                scheduleService.fetchMessageScheduleGroupById(groupId).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });
        } else if (mode === 'content_schedule') {
            return new Promise((resolve, reject) => {
                scheduleService.fetchContentScheduleGroupById(groupId).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });
        } else if (mode === 'event_schedule') {
            return new Promise((resolve, reject) => {
                scheduleService.fetchEventScheduleGroupById(groupId).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });
        } else if (mode === 'playlist') {
            return new Promise((resolve, reject) => {
                const param = {
                    groupId : groupId,
                    groupType : "SUB_GROUP"
                }
                playlistService.fetchPlaylistGroups(param).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });

        } else if (mode === 'content') {
            return new Promise((resolve, reject) => {
                contentService.fetchGroupById(groupId, params).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });
        } else if (mode === 'user' || mode === 'selectedUser' || mode === 'ldapUser') {
            return new Promise((resolve, reject) => {
	    	//[SF00201520] Extra fix: To make 'loading circle' disappear when admin requests Administartor Org's child nodes.
                if (groupId !== undefined && (groupId === '0' || groupId === "0")){
                    resolve();
                }
		    //[SF00201520] Extra fix: Catching exception when user unauthorised to view groups in an Org.
                if (groupId !== undefined && groupId !== '0' && groupId !== "0") {
                        userService.fetchUserSubGroupById(groupId).then(
                            res => this.createChildNode(groupId, res, resolve, reject)
                        ).catch(error=>{
                            toastr.error(t("ERROR_NO_ROLE"));
                            resolve();
                        });
                }
            });
        } else if (mode === 'category') {
            return new Promise((resolve, reject) => {
                commonService.fetchCategoryById(groupId).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });
        } else if (mode === 'ruleset') {
            return new Promise((resolve, reject) => {
                rulesetService.fetchRulesetGroupsById(groupId).then(
                    res => this.createChildNode(groupId, res, resolve, reject)
                )
            });
        } else if (mode === 'brandCode') {
            return new Promise((resolve, reject) => {
                settingService.fetchBrandCodeList(groupId).then(
                    res => {resolve();}
                )
            });

        } else if (mode === 'soldToCode') {
            return new Promise((resolve, reject) => {
                settingService.fetchSoldToCodeList(groupId).then(
                    res => this.createSoldToCodeChildNode(groupId, res, resolve, reject)
                )
            });

        }
    };

    createChildNode = (groupId, res, resolve, reject) => {
        if (res.items) {
            const groups = this.state.groups;
            const treeData = this.getNewTreeData(groups, groupId, this.generateTreeNodes(res.items), 7);
            this.setState({groups: treeData});
            resolve();
        } else {
            toastr.error('unknown');
            reject();
        }
    };

    //SLM Direct
    createSoldToCodeChildNode = (groupId, res, resolve, reject) => {
        if (res.items) {
            const groups = this.state.groups;
            const treeData = this.getNewTreeData(groups, groupId, this.generateSoldToCodeTreeNodes(res.items), 7);
            this.setState({groups: treeData});
            resolve();
        } else {
            toastr.error('unknown');
            reject();
        }
    };

    selectTreeNode = (tempGroupId, event) => {
        const {isSearchMode} = this.state;
        const {mode} = this.props;
        if (!this.state.checkbox) {

            let group;
            if (mode === 'soldToCode'|| mode == 'brandCode') {
                group = this.findGroup(this.state.groups, event.node.props.eventKey);
            } else {
                group = this.findGroup(mode === 'device' || (isSearchMode && mode !== 'device') ? this.state.treeData : this.state.groups, parseInt(event.node.props.eventKey));
            }

            if(group && group.groupId > -1) {
                this.setState({
                    ...this.state,
                    selected: [{...group}],
                    selectedKeys: [group.groupId.toString()]
                });
            }
        } else {
            const groupId = parseInt(event.node.props.eventKey);
            const {checkedKeys, selected} = this.state;
            if (checkedKeys !== undefined && checkedKeys.length > 0) {
                const found = checkedKeys.find(id => id === groupId);
                if (found !== undefined) {
                    this.setState({
                        checkedKeys: checkedKeys.filter(id => id !== groupId),
                        selected: selected.filter(group => group.groupId !== groupId)
                    });
                } else {
                    this.setState({
                        checkedKeys: checkedKeys.concat(groupId),
                        selected: selected.concat({groupId: groupId, groupName: event.node.props.groupName})
                    });
                }
            } else {
                this.setState({
                    checkedKeys: [].concat(groupId),
                    selected: selected.concat({groupId: groupId, groupName: event.node.props.groupName})
                });
            }
        }
    };

    findGroup = (groups, groupId) => {
        for(let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if(group.groupId === groupId) {
                return group;
            }
            if(group.children && group.children.length) {
                let childrenGroup = this.findGroup(group.children, groupId);
                if(childrenGroup && childrenGroup.groupId) {
                    return childrenGroup;
                }
            }
        }
    };

    updateFilterValue = () => {
        this.filterKeys = [];
        this.setState({
            ...this.state,
            filter: this.state.inputText
        })
    };

    onExpand = (expandedKeys) => {
        this.filterKeys = undefined;
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded chilren keys.
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onCheck = (checkedKeys, e) => {
        // get group id, group name
        //const selectedGroupId = e.checkedNodes[0].key;
        const {checked} = e;
        let selectedGroupId = String(e.node.props.groupId);
        let {expandedKeys, checkedKeys : checkedKeysState} = this.state;
        if (this.state.checkbox && e.checkedNodes) {
            /*let groups = [];*/
            let {selected: groups} = this.state;
            if (this.props.checkWithChildren){
                let updatedCheckedKeys = checkedKeys.checked;
                if (!checked) {
                    // checked
                    groups = this.state.selected.filter(group => group.groupId !== parseInt(selectedGroupId, 10))
                    updatedCheckedKeys = updatedCheckedKeys.filter(groupId=> groupId !== parseInt(selectedGroupId, 10));
                } else {
                    selectedGroupId = parseInt(selectedGroupId);
                    function searchTree(element, groupId){
                        if(element.groupId == groupId){
                            return element;
                        }else if (element.children != null){
                            let i, result = null;
                            for(i=0; result == null && i < element.children.length; i++){
                                result = searchTree(element.children[i], groupId);
                            }
                            return result;
                        }
                        return null;
                    }
                    const findChildren = (group) => {
                        if (!group.disabled) {
                            groups.push(group);
                            expandedKeys.push(String(group.groupId));
                            updatedCheckedKeys.push(String(group.groupId));
                            if (group.children) {
                                for (const childGroup of group.children) {
                                    findChildren(childGroup);
                                }
                            }
                        }
                    }
                    for (const organizationGroup of this.state.treeData) {
                        const currentGroup = searchTree(organizationGroup, selectedGroupId);
                        if (currentGroup !== null) {
                            findChildren(currentGroup);
                            break;
                        }
                    }
                }
                this.setState({
                    ...this.state,
                    selected: groups,
                    checkedKeys : {
                        ...this.state.checkedKeys,
                        checked: updatedCheckedKeys
                    },
                    expandedKeys,
                });
            } else {
                groups = [];
                e.checkedNodes.map(node=> {
                    groups.push({groupId: Math.trunc(node.key), groupName: node.props.title, groupNameText: node.props.groupName});
                    expandedKeys.push(node.key);
                });
                this.setState({
                    ...this.state,
                    selected: groups,
                    checkedKeys,
                    expandedKeys,
                });
            }
        }
    };

    filterTreeNode = (treeNode) => {
        return this.filterFn(treeNode.props.title);
    };

    filterFn = (name) => {
        if (this.state.filter && name.indexOf(this.state.filter) > -1) {
            return true;
        }
        return false;
    };

    onResetClick = () => {
        this.searchRef.current.value = '';
        this.initGroupState();
        const {mode} = this.props;
        switch (mode) {
            case 'device':
                this.getDeviceGroupData();
                break;
            default:
                break;
        }
    };

    onKeyDownForSearch = e => {
        if (e.keyCode === 13) {
            this.onSearch();
        }
    };

    onSearch = () => {
        const value = this.searchRef.current.value;
        const {mode, params, t} = this.props;
        this.updateFilterValue();
        const updateGroupState = (res, isOrganizationIcon = true) => {
            if (res && res.items) {
                const groups = jsonToTreeForDeviceAfterSearch(mode, res.items, false, false);
                this.setState({
                    treeData: groups.treeData,
                    loading: false
                })
            }
        };
        const searchGroup = (mode) => {
            switch (mode) {
                case 'device':
                    let params = {
                        searchText: value,
                        organizationId: this.props.organizationId,
                        allEnabled: this.props.allEnabled
                    };
                    deviceService.searchGroupByName(params).then(
                        res => {
                            if (res) {
                                if(value === ""){
                                    this.getDeviceGroupData();
                                }else{
                                    const groups = jsonToTreeForDeviceAfterSearch(mode, res.items);
                                    this.setState({
                                        treeData: groups.treeData,
                                        defaultCheckedKeys: groups.defaultCheckedKeys,
                                        defaultSelectedKeys: groups.defaultCheckedKeys,
                                    })
                                }
                            } else {
                                toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                            }
                        }
                    );
                    break;
                case 'content_schedule':
                    scheduleService.searchContentScheduleGroupByGroupName(value).then(updateGroupState);
                    break;
                case 'message_schedule':
                    scheduleService.searchMessageScheduleGroupByGroupName(value).then(updateGroupState);
                    break;
                case 'event_schedule':
                    scheduleService.searchEventScheduleGroupByGroupName(value).then(updateGroupState);
                    break;
                case 'content':
                    //SF[00207800]
                    const param = {
                        searchText: value,
                        groupType: this.props.params.groupType
                    };
                    contentService.searchContentGroupByGroupName(param).then(updateGroupState);
                    break;
                case 'playlist':
                    playlistService.searchPlaylistGroupByGroupName(value).then(updateGroupState);
                    break;
                case 'ruleset':
                    rulesetService.searchRulesetGroupByGroupName(value).then(updateGroupState);
                    break;
                case 'selectedUser':
                case 'user':
                case 'ldapUser':
                    userService.searchUserGroupByGroupName(value).then(updateGroupState);
                default:
                    break;
            }
        };

        if (mode !== 'device') {
            if (value === '') {
                this.setState({isSearchMode : false})
            } else {
                this.setState({isSearchMode : true, loading: true})
                searchGroup(mode);
            }
        } else {
            searchGroup(mode);
        }

    }

    changeAllSelect = (event) => {
        const {treeData} = this.state;
        let expandedKeys = [];
        let checkedKeys = [];
        let selected = [];
        let autoExpandParent = false;
        const loopCheck = (group) => {
            expandedKeys.push(group.key);
            group.children.map((child) => {
                if(child.disableCheckbox !== true){
                    expandedKeys.push(child.key);
                    checkedKeys.push(child.key);
                    selected.push({groupId:child.key, groupName:child.groupName});
                }
                loopCheck(child);
            });
        };
        if(event.target.checked){
            treeData.map((group) => loopCheck(group));
            autoExpandParent = true;
        }
        this.setState({
            ...this.state,
            checkedKeys,
            expandedKeys,
            selected,
            autoExpandParent,
            isCheckAll: event.target.checked,
        });
    };

    onSave() {
        const {t, mode, save, allowEmptySave} = this.props;
        const {selected, checkedKeys, groups} = this.state;
        
        if(checkedKeys == null){
            toastr.error(t('TEXT_TITLE_SELECT_GROUP_P'));
            return;
        }
        let checked  = checkedKeys.checked === undefined ? checkedKeys : checkedKeys.checked;

        if (mode === 'category') {
            let selectedList = [];
            for (let i = 0; i < checked.length; ++i) {
                if (checked[i] === '') {
                    continue;
                }

                selectedList.push(this.findGroup(groups, parseInt(checked[i])));
            }

            if (selectedList === undefined || selectedList.length === 0) {
                toastr.error(t('TEXT_TITLE_SELECT_GROUP_P'));
            } else {
                save(selectedList)
            }

            return;
        }

        if (allowEmptySave !== undefined && allowEmptySave === true) {
            save(selected)
        } else {
            if (selected === undefined || selected.length === 0) {
                toastr.error(t('TEXT_TITLE_SELECT_GROUP_P'));
            } else {
                save(selected)
            }
        }
    }

    onRightClick = info => {
        const {top, left} = this.groupContainerRef.current.getBoundingClientRect();
        const {node: {props: {groupId}}} = info;
        this.setState({contextMenu: {
            show: true, 
            position: {x: info.event.clientX - left, y: info.event.clientY - top + 10}, 
            selected: groupId
        }}, () => {
            if(this.contextMenuRef.current) {
                this.contextMenuRef.current.focus();
            }
        });
    };

    removeInputNode = () => {
        const {treeData} = this.state;
        const newTreeData = [...treeData];
        newTreeData.forEach(removeInputNode);
        this.setState({treeData: newTreeData});
    };

    checkGroupNameValidation = (name) => {
        const {t} = this.props;  
        if(name.length === 0){
            toastr.error(t("MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG"));
            return false;
        }
        if(name.length > 100){
            toastr.error(getMixString(['COM_SID_SCHEDULE_MIX_MAX_LENGTH', '100']));
            return false;
        }
        return true;
    };

    handleKeyDownForCrate = handler => e => {
        if (e.keyCode === 13) {
            if(this.checkGroupNameValidation(e.target.value)) {
                handler && handler(e, () => {
                    this.getDeviceGroupData();
                });
            }
        } else if(e.keyCode === 27) {
            this.removeInputNode();
        }
    };

    handleBlurForCreate = handler => e => {
        if(this.checkGroupNameValidation(e.target.value)) {
            handler && handler(e, () => {
                this.getDeviceGroupData();
            });
        }
    };

    onCreate = (e, handler) => {
        const {treeData, expandedKeys} = this.state;
        const newTreeData = [...treeData];
        const selected = e.target.getAttribute('data-selected');
        const selectedNode = findNode(newTreeData, selected);

        e.preventDefault();
        if(selectedNode) {
            selectedNode.children.push({
                key: `${selectedNode.key}_${selectedNode.children.length}`,
                icon: <DefaultPlayer />,
                title: <input type='text' className='tempGroupName' defaultValue='new' data-parent-id={selected} maxLength={64} autoFocus onKeyDown={this.handleKeyDownForCrate(handler)} onBlur={this.handleBlurForCreate(handler)} />,
                inputNode: true,
            });
            this.setState({treeData: newTreeData, expandedKeys: [...expandedKeys, selected], contextMenu: {show: false}});
        } else {
            this.setState({contextMenu: {show: false}});
        }
    };

    render() {
        const {t, mode, save, close, checkbox, selectAll, disableSearchToolbar, allowSelectOrganization, title = t("TEXT_SEL_GROUP_P"), contextMenuHandler, allowCheckOrganization} = this.props;
        const {isCheckAll = false, checkedKeys, contextMenu, loading, isSearchMode} = this.state;
        const dialogProps ={title: title, closeOnEscape : true, width :355, height :400 , modal:true, onClose:()=> close()};
        const loop = (data) => {
            return data.map((item) => {
                if (this.filterKeys && this.filterFn(item.name)) {
                    this.filterKeys.push(item.key);
                }
                if (mode === 'playlist' || mode === 'content') {
                    if (item.name === 'default' && item.parentGroupId === -1) {
                        item.isLeaf = true;
                    }
                }
                let disabled = false;
                let selectable = true;
                if(mode === 'ldapUser') {
                    if (item.parentGroupId !== 0 && !(item.ldapEnable === true && item.ldapSyncEnable === true)) {
                        disabled = true;
                    }
                    if(item.parentGroupId === 0) {
                        selectable = false;
                    }
                }

                else if(mode === 'ruleset') {
                    if(!allowSelectOrganization && item.parentGroupId === 0) {                        
                        selectable = false;
                    }
                }
                else if(mode === 'event_schedule' || mode === 'content_schedule' || mode === 'message_schedule') {
                    if(item.parentGroupId === 0) {                       
                        selectable = false;
                    }
                }

                let checkable;
                if(checkbox) {
                    if(item.groupId === 0) {
                        checkable = true;
                    }
                    else {
                        if(allowCheckOrganization === undefined ? true : allowCheckOrganization) {
                            checkable = true;
                        }
                        else {
                            checkable = item.groupId !== item.organizationId;
                        }
                    }
                }
                else {
                    checkable = false;
                }

                if (item.children) {
                    return <TreeNode checkable={checkable} icon={Icon(mode, item, disabled)} title={item.name} key={item.key} isLeaf={item.isLeaf} disabled={disabled} selectable={selectable}>{loop(item.children)}</TreeNode>;
                }
                return (
                    //disableCheckbox
                    <TreeNode checkable={checkable} icon={Icon(mode, item, disabled)} title={item.name} key={item.key}  isLeaf={item.isLeaf} disabled={disabled} selectable={selectable}/>
                );
            });
        };
        let expandedKeys = this.state.expandedKeys;
        let autoExpandParent = this.state.autoExpandParent;
        if (this.filterKeys) {
            expandedKeys = this.filterKeys;
            autoExpandParent = true;
        }

        const treeNodes = loop(this.state.groups);
        return (
            <MISDialog
                classname={ mode === 'category' ? "group_popup_container_category" : (mode === 'selectedUser' ? ["group_popup_container", "userGroup"] : "group_popup")}
                dialog={dialogProps}
                buttons={{
                    rightButtons: [
                        {id: "SAVE_GROUP_POPUP_BTN", title: t("COM_BUTTON_SAVE"), onClick : ()=> this.onSave()},
                        {id: "CANCEL_GROUP_POPUP_BTN", title: this.props.noTitle !== undefined ? this.props.noTitle : t("BUTTON_CANCEL_P"), onClick: close}
                    ]
                }}
            >
                <div className="group_popup_container" ref={this.groupContainerRef}>
                    <div className="popup_contents" style={mode === 'userDevicePermission' ? {height:'calc(100% - 60px)', width:'300px'} : {height:'calc(100% - 60px)'}}>
                        {selectAll &&
                            <Checkbox id={'ALL'} name={t('BUTTON_ALL_SELECT_P')} checked={isCheckAll} onChange={this.changeAllSelect}/>
                        }
                        {
                            disableSearchToolbar !== true &&
                            <div style={{height:40,lineHeight:40,verticalAlign:'middle',textAlign:'right',marginTop:10, display: 'flex'}}>
                                <input type="text" style={{width:180}} placeholder={ (mode === 'content' || mode === 'playlist' || mode === 'ruleset' ) ? t("TEXT_FOLDER_NAME_P") : t("COM_TABLE_GROUP_NAME_P")} value={this.state.inputText} onChange={(e) => this.setState({...this.state, inputText:e.target.value})} onKeyDown={this.onKeyDownForSearch} ref={this.searchRef}/>
                                <SearchButton classname={"ml10"} onClick={this.onSearch}/>
                                <button className="base ml10" style={{width:50}} id="searchGroupResetBtn" onClick={()=>this.onResetClick()}>
                                    <span style={{width:40,textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{t("COM_DID_LFD_RESET")}</span>
                                </button>
                            </div>
                        }

                        <div className="pop_list" style={{overflowX:'auto',borderTop:'1px solid #e7e7e7', padding: 10}}>

                            {
                                loading === true &&
                                <div style={{display: 'flex', justifyContent: 'center'}}><Loading sizes={10}/></div>
                            }

                            {
                                isSearchMode && this.state.treeData !== undefined &&
                                    <Tree
                                        showIcon={true}
                                        checkable={this.props.checkbox === undefined ? false : this.props.checkbox}
                                        onSelect={(key, event)=>this.selectTreeNode(key, event)}
                                        selectedKeys={this.state.selectedKeys}
                                        selectable

                                        // load all searched groups from server
                                        treeData={this.state.treeData}
                                    />


                            }

                            {// for device tree ( do not support to call sub groups)
                                mode === 'device' && this.state.treeData !== undefined &&
                                    <Tree
                                        showIcon={true}
                                        expandedKeys={expandedKeys}
                                        onExpand={this.onExpand}
                                        checkable={this.props.checkbox === undefined ? false : this.props.checkbox}
                                        checkedKeys={checkedKeys}
                                        onCheck={this.onCheck}
                                        onSelect={(key, event)=>this.selectTreeNode(key, event)}
                                        selectedKeys={this.state.selectedKeys}
                                        selectable
                                        // load all groups from server
                                        treeData={this.state.treeData}
                                        // selectable={false}
                                        defaultCheckedKeys={this.state.defaultCheckedKeys}
                                        checkStrictly={true}
                                        onRightClick={contextMenuHandler ? this.onRightClick : undefined}
                                        //filterTreeNode={this.filterTreeNode}
                                    />
                            }
                            {// for others tree ( tree have to call sub groups)
                                !isSearchMode && mode !== 'device' &&
                                    <Tree
                                        showIcon={true}
                                        expandedKeys={expandedKeys}
                                        onExpand={this.onExpand}
                                        checkable={this.props.checkbox === undefined ? false : this.props.checkbox}
                                        checkedKeys={checkedKeys}
                                        onCheck={this.onCheck}
                                        onSelect={(key, event)=>this.selectTreeNode(key, event)}
                                        selectedKeys={this.state.selectedKeys}
                                        selectable
                                        checkStrictly={this.props.checkStrictly}
                                        // load organizations from server
                                        loadData={this.onLoadData}
                                        autoExpandParent={autoExpandParent}
                                        filterTreeNode={this.filterTreeNode}
                                    >
                                        {treeNodes}
                                    </Tree>
                            }
                        </div>
                        {
                            contextMenuHandler && contextMenu.show &&
                            <div className='group_context_menu' tabIndex={-1} style={{position: 'absolute', left: contextMenu.position.x, top: contextMenu.position.y}} onBlur={() => this.setState({contextMenu: {show: false}})} ref={this.contextMenuRef}>
                                {
                                    !isEmpty(contextMenuHandler) &&
                                    Object.entries(contextMenuHandler).map(([propName, handler], i) => {
                                        if(propName === 'onCreate') {
                                            return (
                                                <div key={i} className='normal'><a href='#' onMouseDown={e => this.onCreate(e, handler)} data-selected={contextMenu.selected}>{t('MIS_BUTTON_ADD_GROUP_P')}</a></div>
                                            );
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </MISDialog>
        )
    }
}
GroupsPopup.propsType = {
    checkWithChildren : false
}
export default withTranslation()(GroupsPopup);